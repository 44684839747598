@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "N27";
  src: url("../fonts/n27-regular-webfont.woff") format("woff"),
    url("../fonts/n27-regular-webfont.woff2") format("woff2"),
    url("../fonts/n27-regular-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "Lao MN";
  src: url("../fonts/lao-mn.ttf") format("truetype");
}

html,
body,
#root,
.layout-container {
  height: 100%;
}

html {
  --color-primary: #131313;
  --color-secondary: #f5f5f5;
  --color-tertiary: #ffd700;
}

.join-button {
  z-index: 3;
  position: relative;
}
body {
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.logo-container img {
  max-width: calc(49px + 3vw);
  max-height: 100px;
  margin: 0px 10px;
}

.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layout-outlet {
  flex: 1;
}

.glow {
  color: #ffd700;
  animation-name: glow;
  animation-duration: 1.3s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  font-size: 2rem;
  text-decoration: none !important;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 13px #ffffff, 0 0 16px #ffffff, 0 0 19px #ffd700,
      0 0 22px #ffd700, 0 0 25px #ffd700;
  }

  100% {
    text-shadow: 0 0 1px #ffffff, 0 0 3px #ffffff, 0 0 6px #ffd700,
      0 0 9px #ffd700, 0 0 12px #ffd700;
  }
}

// NAV

.hamburger-menu {
  min-height: 100vh;
  min-width: 100vw;
  background-color: var(--color-primary);
  position: absolute;
  bottom: 100vh;
  transition: bottom 0.5s ease-in;
  z-index: 4;
  left: 0;
}

.hamburger-icon {
  z-index: 123;
}

.zero-vh {
  bottom: 0vh !important;
}

nav {
  z-index: 23;
  height: 100vh;
}

ul {
  height: 60%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

nav ul li a {
  color: var(--color-secondary);
  font-family: "N27", sans-serif;
  text-decoration: none;
  font-size: 1.8em;
  &:hover {
    font-weight: bold;
  }
}

nav span {
  display: none;
}

nav .active span {
  display: unset;
}

.active {
  font-weight: bold;
}

footer ul {
  align-items: start;
}

footer ul li a {
  color: var(--color-secondary);
  text-decoration: none;
  font-family: "N27", sans-serif;
  &:hover {
    text-decoration: underline !important;
  }
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.main-heading-home {
  width: 90%;
  animation: heading 4s forwards 2s;
  opacity: 0;
  transform: translateY(90px);
  top: 30%;
}

// .subheading-home {
//   animation: subheading 4s forwards 4s;
//   opacity: 0;
//   transform: translateY(90px);
// }

video {
  border-radius: 5px;
}

.article-carousel .carousel-control-prev {
  left: -40px;
}
.article-carousel .carousel-control-next {
  right: -40px;
}

@keyframes heading {
  0% {
    opacity: 0;
    transform: translateY(90px);
  }

  40% {
    opacity: 0;
    transform: translateY(90px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

// @keyframes subheading {
//   0% {
//     opacity: 0;
//     transform: translateY(90px);
//   }

//   40% {
//     opacity: 0;
//     transform: translateY(90px);
//   }

//   100% {
//     opacity: 1;
//     transform: translateY(0px);
//   }
// }

.fade-in {
  opacity: 0;
  animation: fadeIn 2s forwards 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.language-icon path {
  color: #fff !important;
  stroke: #fff;
  stroke-width: 1;
}

.join-button {
  bottom: 200px;
}

.join-button a {
  text-decoration: none;
  color: var(--color-primary);
}

.scale-up {
  transform: scale(1);
  transition: transform 0.4s ease-out;
  &:hover {
    transform: scale(1.15);
  }
}

.carousel a {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.carousel-item img {
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
  aspect-ratio: 3/2;
}

.carousel-caption {
  background: linear-gradient(transparent, white 25%);
  color: #131313;
  font-family: "N27", sans-serif;
  bottom: 23px;
  position: relative;
  right: 0;
  left: 0;
  border-radius: 0 0 5px 5px;
  padding-top: 25px;
}

.carousel-indicators {
  bottom: -35px;
}
.article-carousel .carousel-indicators {
  bottom: -55px;
}

.home-content {
  animation: home-content 4s forwards 3s ease-out;
  position: relative;
  top: 60px;
  opacity: 0;
}

.video-container svg {
  font-size: 2em;
}

.video-speaker-icon {
  bottom: 60px;
  right: 10px;
}

.carousel-indicators [data-bs-target] {
  background-color: #fff;
  margin: 10px 2px 10px 2px;
}

@keyframes home-content {
  0% {
    opacity: 0;
    top: 60px;
  }

  40% {
    opacity: 0;
    top: 60px;
  }

  100% {
    opacity: 1;
    top: 0px;
  }
}

@media (min-width: 769px) {
  .article-carousel .carousel-control-prev {
    left: -70px;
  }
  .article-carousel .carousel-control-next {
    right: -70px;
  }
}

.canvas-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.shirt-container canvas {
  min-height: 40vh;
}
